<template>
  <div class="page1">
    <!--主体内容-->
    <div class="contentRoadside">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Equipment_name')">
                <el-input
                  v-model="formInline.monitorEquipmentName"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.park_name')">
                <auto-complete
                  ref="select"
                  @dataChange="(v) => (temParkData = v)"
                  slaveRelations="0,1,2,3,4"
                  @valueChange="completeValue"
                  @queryPark="queryPark"
                  :parkData="parkData"
                ></auto-complete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Belonging_operator')">
                <el-select
                  v-model.trim="formInline.operationId"
                  filterable
                  size="15"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in roleList"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                v-if="$route.meta.authority.button.query"
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left">
              <el-button
                type="primary"
                icon="el-icon-plus"
                v-if="$route.meta.authority.button.add"
                @click="$router.push('/addVideoMonitorEquipRoadside')"
                >{{ $t('button.addto') }}</el-button
              >
              <!-- 权限控制: v-if='$route.meta.authority.button.import' -->
              <el-button
                v-if="$route.meta.authority.button.import"
                plain
                icon="el-icon-upload"
                @click="$refs.importFileOutNvr.dialogVisible = true"
                :loading="loading"
                >批量{{ $t('button.import') }}</el-button
              >
            </div>
            <div class="col_right mbd4"></div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            :label="$t('list.index')"
            width="70"
            align="center"
          ></el-table-column>
          <!-- <el-table-column type="selection" width="55"></el-table-column> -->
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
          ></el-table-column>
          <el-table-column
            label="操作"
            v-if="
              $route.meta.authority.button.edit ||
              $route.meta.authority.button.delete ||
              true
            "
            align="center"
            width="90"
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    command="a"
                    v-if="$route.meta.authority.button.monitor"
                    >查看监控</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="b"
                    v-if="$route.meta.authority.button.edit"
                    >编辑</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="c"
                    v-if="$route.meta.authority.button.delete"
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <video-monitor
      ref="videoMonitor"
      :passageway-list="passagewayList"
      :isShowChange="isShowChange"
      @change="videoChange"
      v-if="isShowVideoDialog"
      :title="title"
      :isRoadOutside="true"
      :isPlayBack="isPlayBack"
      @close="isShowVideoDialog = false"
    ></video-monitor>
    <!-- 导入组件 -->
    <import-file-out-nvr
      ref="importFileOutNvr"
      @success="
        page = 1;
        searchData();
      "
      :uploadData="{}"
      uploadUrl="/acb/2.0/bacb/monitorEquipment/import"
      modelUrl="/acb/2.0/bacb/monitorEquipment/downloadExcelTemplate"
      itemName="NVR管理"
    />
  </div>
</template>

<script>
import autoComplete from "@/components/autocomplete/autocompletaRoadside";
import VideoMonitor from "@/components/video-monitor/";
// import importFileOutNvr from "@/components/importFileOutNvr/importFileOutNvr"
import importFileOutNvr from "@/components/uploadFileCommon/monitor";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      passagewayList: [],
      isShowVideoDialog: false,
      isShowChange: false,
      title: "",
      temParkData: {},
      parkData: [],
      index: 0,
      total: 0,
      pageSize: 15,
      page: 1,
      parkPage: 1,
      loading: false,
      detailFlag: false,
      roleList: [],
      tableCols: [
        {
          prop: "monitorEquipmentName",
          // label: this.$t("list.Equipment_name"),
          label: this.$t("list.Equipment_name"),
          width: "",
        },
        {
          prop: "cityName",
          // label: this.$t("list.City_affiliation"),
          label: this.$t("list.City_affiliation"),
          width: "",
        },
        {
          prop: "areaName",
          // label: this.$t("list.Belonging_region"),
          label: this.$t("list.Belonging_region"),
          width: "",
        },
        {
          prop: "parkName",
          // label: this.$t("list.Belonging_parking_lot"),
          label: this.$t("list.Belonging_parking_lot"),
          width: "",
        },
        {
          prop: "ipAddress",
          // label: this.$t("list.IP_address"),
          label: this.$t("list.IP_address"),
          width: "",
        },
        {
          prop: "ipPort",
          // label: this.$t("list.Port_number"),
          label: this.$t("list.Port_number"),
          width: "",
        },
        {
          prop: "monitorPort",
          // label: this.$t("list.video_Port_number"),
          label: this.$t("list.video_Port_number"),
          width: "",
        },
        // {
        //   prop: "account",
        //   label: "用户名",
        //   width: ""
        // },
        // {
        //   prop: "password",
        //   label: "密码",
        //   width: ""
        // },
        {
          prop: "createdTime",
          // label: this.$t("list.update_time"),
          label: this.$t("list.update_time"),
          width: "",
        },
      ],
      tableData: [],
      formInline: {
        monitorEquipmentName: "",
        parkId: "",
        operationId: "",
      },
      passagewayPageSize: 5,
      passagewayPage: 1,
      passagewayTotal: 0,
      selectData: {},
      isPlayBack: true, // 是否支持回放
    };
  },
  watch: {
    isShowVideoDialog: function (val, oldVal) {
      console.log("是否显示--->", val);
      if (!val) {
        // window.location.reload();
        /* this.videoIdArr.forEach(item => {
          sessionStorage.removeItem(item)
        }); */
      }
    },
  },
  methods: {
    ...mapMutations({
      playViewFlag: "SET_PlayViewFlag", // 将 `this.add()` 映射为`this.$store.commit('increment')`
    }),
    JSONParse(objStr) {
      return JSON.parse(objStr, (k, v) => {
        if (typeof v === "string" && v.indexOf && v.indexOf("function") > -1) {
          // eval 可能在eslint中报错，需要加入下行注释
          // eslint-disable-next-line
          return eval(`(function(){return ${v}})()`);
        }
        return v;
      });
    },
    async videoChange() {
      if (this.passagewayPage > this.passagewayFlag) {
        this.passagewayPage = 1;
      }
      await this.getPassagewayList(this.selectData.monitorEquipmentId);
      this.$nextTick(() => {
        this.$refs.videoMonitor.clearData();
      });
    },
    getPassagewayList(monitorEquipmentId) {
      return new Promise((resolve, reject) => {
        this.$axios
          .get("/acb/2.0/bacb/monitorEquipmentChannel/list", {
            data: {
              pageNum: this.passagewayPage,
              pageSize: this.passagewayPageSize,
              monitorEquipmentId,
            },
          })
          .then((res) => {
            if (res.state == 0) {
              this.passagewayList = res.value.list;
              this.passagewayTotal = res.value.total;
              this.passagewayFlagA =
                this.passagewayTotal % this.passagewayPageSize;
              this.passagewayFlagB = parseInt(
                this.passagewayTotal / this.passagewayPageSize
              );
              if (this.passagewayFlagA > 0) {
                this.passagewayFlag = this.passagewayFlagB + 1;
              } else {
                this.passagewayFlag = this.passagewayFlagB;
              }
              console.log(this.passagewayFlag);
              this.passagewayPage++;
              // this.passagewayFlag = this.passagewayTotal/this.passagewayPageSize
            } else {
              this.passagewayPage = 1;
              this.passagewayList = [];
              this.passagewayTotal = 0;
            }
            if (this.passagewayTotal > 5) {
              this.isShowChange = true;
            } else {
              this.isShowChange = false;
            }
            resolve();
          });
      });
    },
    /* 商户名称的下拉框 */
    getRoleList() {
      this.$axios
        .get("/acb/2.0/operation/nameList", {
          data: {},
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.roleList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
      // let opt = {
      //   method: 'get',
      //   url: '/acb/2.0/parkRecord/operation/list',
      //   data: {
      //     page: 0,
      //     pageSize: 0,
      //     companyName: '',
      //     bearSide: '',
      //   },
      //   success: res => {
      //     this.loading = false;
      //     if (res.state == 0) {
      //       this.roleList = res.value;
      //     } else {
      //       this.$alert(res.desc, this.$t('pop_up.Tips'), {
      //         confirmButtonText: this.$t('pop_up.Determine'),
      //       });
      //     }
      //   },
      // };
      // this.$request(opt);
    },
    addBerth() {
      this.$refs.addBerth.open();
    },
    completeValue(item) {
      this.formInline.parkId = item;
    },
    queryPark() {
      this.parkPage++;
      this.getPark(this.area);
    },
    getPark(areaId) {
      this.area = areaId;
      let url = "/acb/2.0/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: this.parkPage,
            pageSize: 15,
            areaIds: "",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.parkData = res.value.list;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    async handleCommand(cmd, data) {
      if (cmd == "a") {
        // window.open(
        //   "/video.html?" +
        //     data.ipAddress +
        //     "/" +
        //     data.ipPort +
        //     "/" +
        //     data.account +
        //     "/" +
        //     data.password +
        //     "/" +
        //     data.monitorPort,
        //   "查看监控设备"
        // );
        this.passagewayPageSize = 5;
        this.passagewayPage = 1;
        this.passagewayTotal = 0;
        await this.getPassagewayList(data.monitorEquipmentId);
        if (this.passagewayList.length == 0) {
          this.$alert("此NVR下没有监控设备！", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return;
        }
        this.selectData = data;
        this.title = `${data.monitorEquipmentName}`;
        // this.passagewayList = [data];
        this.isShowVideoDialog = true;
        this.$nextTick(() => {
          this.$refs.videoMonitor.open();
        });
      }
      if (cmd == "b") {
        this.$router.push({
          path: "/editVideoMonitorEquipRoadside",
          query: data,
        });
      }
      if (cmd == "c") {
        this.deleteItem(data);
      }
    },
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    // 删除当前设备
    deleteItem({ monitorEquipmentId }) {
      this.$confirm("此操作将删除该条数据, 是否继续?", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post("/acb/2.0/bacb/monitorEquipment/delete/" + monitorEquipmentId)
            .then((res) => {
              this.loading = false;
              if (res.state == 0) {
                this.searchData();
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
              this.loading = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    // 搜索
    searchData() {
      this.tableData = [];
      if (!this.formInline.parkId && Object.keys(this.temParkData).length) {
        this.$refs.select.setShowVal(this.temParkData.parkName);
        this.formInline.parkId = this.temParkData.parkId;
        this.temParkData = {};
      }
      this.loading = true;
      this.$axios
        .get("/acb/2.0/bacb/monitorEquipment/listMonitorEquipment", {
          data: {
            pageNum: this.page,
            pageSize: this.pageSize,
            ...this.formInline,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.tableData = res.value.list;
            this.total = res.value.total * 1;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
          this.loading = false;
        });
    },
  },
  activated() {
    this.searchData();
  },
  beforeDestroy() {
    sessionStorage.searchData = JSON.stringify(this.formInline);
    sessionStorage.searchFlag = 1;
  },
  components: {
    autoComplete,
    VideoMonitor,
    importFileOutNvr,
  },
  created() {
    this.getPark();
    this.searchData();
    this.getRoleList();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  // height: 35px;
}

.contentRoadside {
  overflow: hidden;

  .searchWrapper {
    overflow: hidden;
    // padding: 22px 22px 0;
    // margin-bottom: 20px;
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.el-dropdown-link {
  cursor: pointer;
}
</style>
